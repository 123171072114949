import { debounce } from 'lodash'
import { refreshAuthToken } from './actions'
import { UnauthorizedError } from 'api'
import { isAuthenticatedSelector } from './selectors'
const { location } = window

/**
 * Periodically refreshes the auth token
 */

export default store => {
  const debouncedRefreshAuthToken = debounce(() => store.dispatch(refreshAuthToken()), 2000)
  return next => action => {
    if (action.error && action.error instanceof UnauthorizedError) {
      location.href = '/login'
    } else if (action.type === '@@router/LOCATION_CHANGE' && isAuthenticatedSelector(store?.getState?.())) {
      debouncedRefreshAuthToken()
    }

    return next(action)
  }
}
