import React from 'react'
import * as blockComponents from 'page-blocks'
import { connect } from 'react-redux'
import { currentUserSelector, isAuthenticatedSelector } from 'auth'

export const shouldDisplayBlock = (block, cmsBlockConditions, isAuthenticated) => {
  const sessionState = isAuthenticated ? 'logged_in' : 'logged_out'
  if (block?.block_content?.display_conditions?.length > 0) {
    const shouldDisplay = block.block_content.display_conditions.find(condition => {
      if (condition.title &&
        condition.title !== 'any' &&
        condition.title !== cmsBlockConditions?.title
      ) {
        return false
      }
      if (condition.member_type && condition.member_type !== 'any' && !cmsBlockConditions?.member_types?.includes?.(condition.member_type)) {
        return false
      }
      if (condition.associated_institution &&
        condition.associated_institution !== 'any' &&
        condition.associated_institution !== cmsBlockConditions?.associated_institution
      ) {
        return false
      }
      if (condition.fsp_alumni_status &&
        condition.fsp_alumni_status !== 'any' &&
        condition.fsp_alumni_status !== cmsBlockConditions?.fsp_alumni_status
      ) {
        return false
      }
      if (condition.session_state &&
        condition.session_state !== 'any' &&
        condition.session_state !== sessionState
      ) {
        return false
      }
      return true
    })
    if (!shouldDisplay) {
      return false
    }
  }
  return true
}

class PageBlockRenderer extends React.Component {
  componentDidUpdate () {
    if (!this.hasScrolledToAnchor) {
      this.props.blocks.forEach((block) => {
        if (block?.block_content?.anchor_tag) {
          const anchor = document.getElementById(block.block_content.anchor_tag)
          if (anchor && window.location.hash === `#${block.block_content.anchor_tag}`) {
            this.hasScrolledToAnchor = true
            anchor.scrollIntoView()
          }
        }
      })
    }
  }

  render () {
    const { blocks, sessionSale, cmsBlockConditions, isAuthenticated } = this.props
    if (!blocks?.length) {
      return null
    }
    return (
      <div>
        {
            blocks.map((block, index) => {
              const BlockComponent = blockComponents[block.block_type]
              if (!BlockComponent) {
                return null
              }
              const shouldDisplay = shouldDisplayBlock(block, cmsBlockConditions, isAuthenticated)
              if (!shouldDisplay) {
                return null
              }
              if (block.block_content?.anchor_tag) {
                return (
                  <div id={block.block_content.anchor_tag} key={index}>
                    <BlockComponent {...block.block_content} session_sale={sessionSale} key={index} />
                  </div>
                )
              }
              return <BlockComponent {...block.block_content} session_sale={sessionSale} key={index} />
            })
          }
      </div>
    )
  }
}

export default connect(
  (state) => ({
    isAuthenticated: isAuthenticatedSelector(state),
    cmsBlockConditions: currentUserSelector(state)?.cmsBlockConditions || {}
  })
)(PageBlockRenderer)
